<template>
  <NotificationBase
    :notification
    :icon
    :compact
    link="/personal/transactions?tab=deposit"
  >
    <template #subject>
      {{ t('notifications.subject.freebetAccrual') }}
    </template>
    <i18n-t
      v-if="notification.data.operationType === 'freebetAccrual'"
      keypath="notifications.body.freebetAccrual"
      tag="p"
    >
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
    <i18n-t
      v-else-if="notification.data.operationType === 'freebetWinAccrual'"
      keypath="notifications.body.freebetWinAccrual"
      tag="p"
    >
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
    <i18n-t v-else keypath="notifications.body.freebetProcessAccrual" tag="p">
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
  </NotificationBase>
</template>

<script setup lang="ts">
import NotificationBase from '../NotificationBase.vue'
import type { AccountTransactionNotification } from '../../../../types'

const { notification, compact = false } = defineProps<{
  notification: AccountTransactionNotification
  compact?: boolean
}>()

const { t } = useI18n()

const { notificationAmount, currencyIcon } = useCurrencyNotification(
  toRef(() => notification),
)

const icon = computed(() => ({
  name: 'freebet' as const,
  currencyIcon: currencyIcon.value,
}))
</script>

<style scoped>
.text {
  margin: 0;

  b {
    color: var(--text-primary);
  }
}
</style>
